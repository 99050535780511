@font-face {
  font-family: 'Farkcom-Font';
  src:  url('/css/fonts/Farkcom-Font.eot?awy0cq');
  src:  url('/css/fonts/Farkcom-Font.eot?awy0cq#iefix') format('embedded-opentype'),
    url('/css/fonts/Farkcom-Font.ttf?awy0cq') format('truetype'),
    url('/css/fonts/Farkcom-Font.woff?awy0cq') format('woff'),
    url('/css/fonts/Farkcom-Font.svg?awy0cq#Farkcom-Font') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Farkcom-Font' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-defer:before {
  content: "\e905";
}
.icon-undefer:before {
  content: "\e906";
}
.icon-checkmark:before {
  content: "\e903";
}
.icon-report:before {
  content: "\e902";
}
.icon-settings:before {
  content: "\e994";
}
.icon-delete-circle:before {
  content: "\ea0d";
}
.icon-delete-bin:before {
  content: "\e9ad";
}
.icon-vote:before {
  content: "\e907";
}
.icon-quarantine:before {
  content: "\e904";
}
.icon-invis:before {
  content: "\e901";
}

