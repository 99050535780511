.vote_button { 
    display: inline-block !important; 
    height: 40px; 
    width: 42px; 
    vertical-align: middle; 
    background-color: transparent;
    background-repeat: no-repeat;
    border: 0;
    direction: ltr;
    overflow: hidden;
    text-align: left;
    text-indent: -999em;
}
.vote_button[data-type='Smartest'], .vote_button[data-type='Smartest'] { width: 52px; }
.vote_button span { 
    background: transparent top left no-repeat; 
    width: 70%; 
    height: 70%; 
    display: block; 
    margin: 6px; 
    opacity: 0.6;
    // background-size: 66px 84px;
}
.vote_button:hover span, .vote_button.vote_active span { opacity: 1 !important; }

/* 
    Icons;
    Smartest: https://www.iconfinder.com/icons/2639826/cap_graduation_icon
    Funniest: https://www.iconfinder.com/icons/210817/emoticon_face_happy_smile_smiley_icon
    Vote: https://www.iconfinder.com/icons/2639931/thumbs_up_icon
*/
.vote_button[data-type='Smartest'] span { background-image: url("https://img.fark.net/images/2019/site/vote-smart.svg"); }
.vote_button[data-type='Funniest'] span { background-image: url("https://img.fark.net/images/2019/site/vote-funny.svg"); }
.vote_button[data-type='Votes'] span { margin-right: 0; background-image: url("https://img.fark.net/images/2019/site/vote-vote.svg");  }

.vote_button.vote_active[data-type='Smartest'] span { background-image: url("https://img.fark.net/images/2019/site/vote-smart-active.svg"); }
.vote_button.vote_active[data-type='Funniest'] span { background-image: url("https://img.fark.net/images/2019/site/vote-funny-active.svg"); }
.vote_button.vote_active[data-type='Votes'] span { background-image: url("https://img.fark.net/images/2019/site/vote-vote-active.svg");  }

.vote_status_box {
    background-color: #fff;
    border: 1px solid #686898;
    bottom: 60px;
    display: inline-block;
    font-size: smaller;
    left: 60px;
    padding: 10px;
    position: absolute;
}
.vote_status_box.Smartest { left: 16px; }
.vote_status_box.Votes { left: 16px; bottom: 52px; }
.comment_vote_button { color: #808080; }
.voted_Votes.ir, .vote_Votes.ir { text-indent: unset; }
